import $ from 'jquery';
import {biMarketingEventRunner} from '../../services/bi/BiMarketingEventRunner.js';

/**
 * MarketingEventV1 widget configs
 */
const marketingEventV1Semantics = {
    'mainWrapClass': 'marketing-event-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
};


$(function () {
    MarketingEventV1.init();
})

class MarketingEventV1 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${marketingEventV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.marketingEventV1) {
            window.widgets.marketingEventV1 = new MarketingEventV1();
        }

        /**
         * Checking other instances
         */
        $(`.${marketingEventV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${marketingEventV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.marketingEventV1.registerInstance($(el));
                window.widgets.marketingEventV1.registerInstanceListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${marketingEventV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${marketingEventV1Semantics.mainWrapClass}`);
            el.attr(`${marketingEventV1Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerInstanceListeners(el) {
        // Читаем данные событий из виджета, если они есть
        const events = el.data('events');
        if (events && events.length > 0) {
            // Если события переданы с сервера, сразу регистрируем их
            biMarketingEventRunner.registerAllEvents(events);
        } else {
            // Если событий нет, делаем запрос на сервер
            this.loadEventsFromServer(el);
        }
    }

    // тянем данные с сервера
    loadEventsFromServer(el) {
        const pageId = el.data('page-id');
        let addParams = "";
        if(pageId !== undefined && pageId !== null && pageId !== '') {
            addParams = "pageId=" + pageId;
        }
        $.ajax({
            method: 'POST',
            url: el.attr('data-url'),
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")},
            dataType: 'json',
            data: addParams,
            success: function (data) {
                biMarketingEventRunner.registerAllEvents(data);
            },
            error: function (xhr, status, error) {
                console.error('Ошибка при загрузке  маркетинговых событий:', error);
            }
        })
    }
}