import $ from 'jquery';

const semantics = {
    'tpWrapClass': 'text-page-delivery-v1',
    'infoBlockSelector': 'section.delivery-fully',
    'formBlockSelector': 'section.delivery-form',
    'brandInputSelector': 'section.delivery-form ._producer select',
    'regionInputSelector': 'section.delivery-form ._city select',
};

$(function() {

    var textPageDeliveryV1 = new TextPageDeliveryV1();
    textPageDeliveryV1.subscribe();
    
});

class TextPageDeliveryV1
{
    constructor() {
        this.tpWrap = $(`.${semantics.tpWrapClass}`);
        this.infoBlock = this.tpWrap.find(semantics.infoBlockSelector);
        this.formBlock = this.tpWrap.find(semantics.formBlockSelector);
        this.brandInput = this.tpWrap.find(semantics.brandInputSelector);
        this.regionInput = this.tpWrap.find(semantics.regionInputSelector);
    }

    updateInfo() {
        
        const self = this;

        console.log({
            brandId: this.brandInput.val(),
            regionId: this.regionInput.val()
        });

        $.ajax({
            url: '/op/delivery/as-json',
            method: 'get',
            data: {
                brandId: this.brandInput.val(),
                regionId: this.regionInput.val()
            },
            success: function (data) {
                self.infoBlock.empty().html(data.data);
            },
            error: function(data) {
                self.infoBlock.empty().html(
                    $('<p>').html(
                        `Упс! На сервере случилась небольшая проблема,\
                        мы уже оповещены и работаем над ней.<br/>\
                        Пожалуйста, попробуйте еще раз позже.`
                    )
                );
            }
        });
    }

    subscribe() {

        const self = this;

        $(this.brandInput).on('change', function(e) {
            e.preventDefault();
            self.updateInfo();
        });

        $(this.regionInput).on('change', function(e) {
            e.preventDefault();
            self.updateInfo();
        });

        $(this.formBlock).on('submit', 'form', function(e) {
            e.preventDefault();
            self.updateInfo();
        });

    }    
}