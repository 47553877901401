/* eslint-disable-next-line no-console */
const size = {
    tablet: 768,
    smDesktop: 1025,
    desktop: 1280,
    laptop: 1360,
    desktopMax: 1604,
};

// https://kenwheeler.github.io/slick/
// https://github.com/kenwheeler/slick/blob/v1.8.1/slick/slick.js#L555
// buildRows:
// rows (int) Default: 1
// Setting this to more than 1 initializes grid mode. 
// Use slidesPerRow to set how many slides should be in each row.
// slidesPerRow (int) Default: 1 
// With grid mode intialized via the rows option, 
// this sets how many slides are in each grid row.
// В slick.js (searchBy:[Slick.prototype.buildRows]) в buildRows
// все элементы слайдера всегда оборачиваются в двойной div, 
// независимо ни от каких других опций (если не трогать опции rows и slidesPerRow)
// Помогает только установка параметра rows в 0 (тупо отключаем работу метода buildRows)
// поэтому данную опцию мы прокидываем автоматически во все виджеты
// как зависимость и при агрегации объекта конфигурации (pluginOptions) 
// добавляем этот объект
const slickCommonOptions = {
    rows: 0,
};

const globalConfig = {
    isDebugMode: false,
};

export {
    size,
    slickCommonOptions,
    globalConfig
};